<template>
  <div class="container">
    <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
    </van-nav-bar>

    <van-tabs v-model="activeName" sticky >
     
      <van-tab title="线下课程" name="a">
        <div class="tabcontent">
          <CourseClass0 :Type="1"></CourseClass0>
        </div>
      </van-tab>
       <van-tab title="线上课程" name="b">
        <div class="tabcontent">
          <CourseClass0 :Type="0"></CourseClass0>
        </div>
      </van-tab>

     
    </van-tabs>
  </div>
</template>

<script>

import CourseClass0 from "./components/courseClass0";


export default {
  name: "zncourseclass",
  data() {
    return {
      scroll: 0,
      activeName: "a",
      title: "课程分类",
      
    };
  },
  components: {
    CourseClass0,
  },
  activated() {},
  created() {
  
  },
  updated() {
    //this.showQW();
  },
 

  methods: {
   
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  //overflow-y: auto;
  //box-sizing: border-box;

  /deep/.van-tabs {
    height: 100%;
  }
  /deep/.van-tabs__content {
    height: 100%;
  }
}
.van-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  /deep/ .van-tabs__wrap {
    height: 36px;
    padding-right: 36px;
    .van-tab {
      line-height: 36px;
    }
    .van-tabs__line {
      background-color: #3296fa;
      height: 2px;
    }
  }
  /deep/ .van-tabs__content {
    flex: 1;
    overflow: hidden;
  }
  /deep/ .van-tab__pane {
    height: 100%;
    .scroll-wrapper {
      height: 100%;
      overflow-y: auto;
    }
  }
}

.tabcontent {
  height: 100%;
  margin-bottom: 138px;
}




</style>
