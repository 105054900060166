<template>
  <div class="container" ref="wrapper">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false">
      <div class="detail">
        <van-row>
          <van-col span="7" class="category-left">
            <van-sidebar v-model="activeKey" @change="selectCategory">
              <van-sidebar-item
                :title="item.text + '(' + item.badge + ')'"
                v-for="item in ccitems"
                :key="item.id"
              />
            </van-sidebar>
          </van-col>
          <van-col span="17" class="category-container">
            <van-cell-group>
              <van-cell
                v-for="item in ccitemsContainer"
                :key="item.id"
                @click="toclick(item)"
                is-link
              >
                <template #title>
                  <span class="custom-title">{{
                    item.text + "(" + item.badge + ")"
                  }}</span>
                </template>
              </van-cell>
            </van-cell-group>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getClassWithChildren } from "@/api/school";

export default {
  props: {
    Type: {
      type: Number,
      default: 0, //0线上课程  1 线下课程
    },
  },
  data() {
    return {
      scroll:0,
      loading: true,
      activeKey: 0,
      urlPath: "",
      citem: [],
      ccitems: [],
      ccitemsContainer: [],
    };
  },
  components: {},
  created() {
    this.initData();
  },
  
  activated() {
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },

  methods: {
    async initData() {
      if (this.Type == 0) {
        this.urlPath = "/course/list?type=a";
      }
      if (this.Type == 1) {
        this.urlPath = "/course/list?type=b";
      }

      let cresult = await getClassWithChildren({
        fromType: 1,
        filterData: { parentID: this.Type },
      });

      this.ccitems = [];
      this.citem = cresult.data.data;
      cresult.data.data.forEach((citem) => {
        this.ccitems.push({
          text: citem.name,
          id: citem.id,
          badge: citem.count,
        });
      });
      this.selectCategory(0);
      this.loading = false;
    },
    selectCategory(index) {
      this.ccitemsContainer = [];
      if (this.citem[index].name == "全部") {
        this.citem.forEach((citem) => {
          var to = "";
          if (citem.name == "全部") {
            to = this.urlPath;
          } else {
            to = this.urlPath + "&id=" + citem.id;
          }
          this.ccitemsContainer.push({
            text: citem.name,
            id: citem.id,
            badge: citem.count,
            to: to,
          });
        });
      } else {
        if (
          this.citem[index].children == undefined ||
          this.citem[index].children.length == 0
        ) {
          //如果没有子类
          var citem = this.citem[index];
          this.$router.push({
            path: this.urlPath + "&id=" + citem.id,
          });

          //   var to = this.urlPath + "&id=" + citem.id;
          //   this.ccitemsContainer.push({
          //     text: "全部",
          //     id: citem.id,
          //     badge: citem.count,
          //     to: to,
          //   });
        } else {
          this.citem[index].children.forEach((citem) => {
            var to = "";
            if (citem.name == "全部") {
              to = this.urlPath + "&id=" + citem.id;
            } else {
              to = this.urlPath + "&id2=" + citem.id;
            }
            this.ccitemsContainer.push({
              text: citem.name,
              id: citem.id,
              badge: citem.count,
              to: to,
            });
          });
        }
      }
    },
    toclick(item) {

let wrapperScrollTop = this.$refs.wrapper.scrollTop;
//存储 scrollTop 的值
this.scroll = wrapperScrollTop;

this.$router.push({
  path: item.to,
  
});
},
  },
};
</script>


<style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.detail {
  padding: 10px 10px 20px;
}
.van-sidebar {
  width: auto;
}
</style>